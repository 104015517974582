:root {
  --main-color: #02b67d;
  --main-color-dark: #017953;
  --main-color-light: #02f2a6;
  --off-color: #39413e;
  --off-color-dark: #090b0a;
  --off-color-light: #55625d;
  --alt-color: #ffffff;
  --alt-color-dark: #c6bec1;
  --alt-color-light: #2b312f;
}
html {
  scroll-behavior: smooth;
}
span {
  color: var(--main-color);
}
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--off-color-dark);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: var(--main-color);
  text-decoration: underline;
}
a:hover {
  color: var(--main-color-dark);
}

.nav {
  list-style-type: none;
  padding: 0;
  float: right;
}

.nav-item {
  display: inline;
  height: 100%;
  padding-left: 1rem;
}
.nav-item a {
  color: var(--off-color);
  text-decoration: none;
}
.nav-item a:hover {
  color: var(--off-color-dark);
  text-decoration: underline;
}
.nav-item .active {
  color: var(--main-color);
}
.nav-item .active:hover {
  color: var(--main-color-dark);
}

.nav-header {
  font-size: 1.1rem;
  justify-content: right;
}

p {
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
}

.main {
  min-height: calc(100vh - 19em);
}

.heading {
  margin-bottom: 2rem;
  font-size: 1.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

#title {
  margin-top: 0.1rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: none;
  max-width: 172px;
  animation: title-animation 1.6s;
  transition-timing-function: ease-in-out;
}
#title a {
  text-decoration: none;
  color: var(--off-color);
}
#title a:hover {
  color: var(--main-color);
}

.hover-space {
  transition: letter-spacing 0.4s ease;
}
.hover-space:hover {
  letter-spacing: 0.32px;
}

@keyframes title-animation {
  0% {
    max-width: 0px;
  }
  100% {
    max-width: 220px;
  }
}

#header {
  padding-top: 3.6em;
  padding-bottom: 1em;
}

#footer {
  margin-bottom: 1.2rem;
}
#footer p {
  font-size: 0.9rem;
  color: var(--off-color);
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
#footer a {
  font-size: 0.9rem;
}

.btn-section-selector {
  background-color: none;
  width: 100%;
  padding: 0;
  margin-bottom: 1em;
  height: 3.6rem;
  transform: skew(-10deg);
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -o-transform: skew(-10deg);
  border: 1px solid var(--main-color);
  border-radius: 0.24rem;
}
.btn-section-selector :hover {
  background-color: var(--main-color);
  height: 3.6rem;
  border: 0 solid var(--main-color);
  border-radius: 0.24rem;
  transition: 0.12s;
}
.btn-section-selector h3 {
  margin: 0;
  padding-top: 0.42em;
  height: 3.6rem;
}
.btn-section-selector a {
  color: var(--main-color);
  text-decoration: none;
}
.btn-section-selector a:hover {
  color: var(--off-color);
}

.breadcrumb-item a {
  text-decoration: none;
}

.tag {
  width: max-content;
  color: var(--off-color-light);
}

.col-social-buttons {
  width: max-content;
  padding: 0;
}

.project-deck {
  padding: 0;
}
.project {
  padding: 1.4em;
  margin: 1em 0 1em 0;
  background-color: var(--alt-color);
}
.project ul {
  margin-top: 0.8em;
  margin-bottom: 0.4em;
  font-size: 0.94em;
}
.project-link {
  text-decoration: none;
}
.project-link:hover {
  text-decoration: none;
}
.btn-primary {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--main-color-dark);
  border-color: var(--main-color-dark);
}
.btn-disabled {
  background-color: var(--alt-color-dark);
}
.btn-link {
  color: var(--main-color);
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: var(--main-color-dark);
}
.btn-secret-link {
  width: max-content;
  height: max-content;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 0.32rem;
  margin-top: -0.24rem;
  text-decoration: underline;
}

/* Add some padding inside the card container */
.cards {
  display: grid;
  grid-gap: 4rem 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: (1, auto);
  margin: 3em 0;
  width: 100%;
}

.card-deck {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 6em;
}

#header-stripe,
#footer-stripe {
  animation: fadein 1.2s ease;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.smiley-easter-egg {
  background-color: white;
  border-radius: 100%;
  position: relative;
  animation: showsmiley 6s;
  transition-timing-function: ease-in-out;
}

@keyframes showsmiley {
  0% {
    top: 5em;
    left: 1.33em;
    opacity: 0;
    transform: rotate(0);
    visibility: visible;
  }
  20% {
    top: -2em;
    left: 1.33em;
    opacity: 1;
    transform: rotate(0);
  }
  40% {
    top: -2em;
    left: 1.33em;
    opacity: 1;
    transform: rotate(0);
  }
  60% {
    top: -2em;
    left: 3.33em;
    opacity: 1;
    transform: rotate(70deg);
  }
  80% {
    top: -2em;
    left: -0.66em;
    opacity: 1;
    transform: rotate(-120deg);
  }
  100% {
    top: -2em;
    left: 16.66em;
    opacity: 0;
    transform: rotate(660deg);
  }
}

/* xl */
/* ... default ... */

/* lg */
@media screen and (max-width: 1200px) {
}

/* md */
@media screen and (max-width: 991px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* sm */
@media screen and (max-width: 767px) {
}

/* xs */
@media screen and (max-width: 575px) {
  :root {
    padding-left: 1em;
    padding-right: 1em;
  }
  .cards {
    grid-template-columns: 1fr;
  }
}
